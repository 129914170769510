export const Callusbnr = () => {
    return (
        <div class="repaircenter">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <div class="repairlocaiton">
                            <h2>RO Water Repair Repair Call- 9102133633</h2>
                            <p>We are providing Repair in Patna  &amp; nearby Patna . So Call us For Instant RO Repair System. Call 24hrs to Book Your Technician 9102133633.  Your water filtration system runs 24*7 to provide you the pure and healthy water. So sometimes it gets some wear and tear which can affect its productivity and life as well. you will get the solution to this problem. Now you may ask when you should go for RO repair.</p>
                            <ul>
                                <ol>When your RO system is fully dead</ol>
                                <ol>When there is any leakage in your water purifier</ol>
                                <ol>When odor and color present in your water</ol>

                            </ul>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}